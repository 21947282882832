<template>
  <div style="height: 100%">
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">人资管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">员工管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">员工详情</span>
        <span style="color:cornflowerblue;margin-left:5px" @click="goBack">返回</span>
      </div>
      <div style="display: flex; align-items: center"></div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <el-card class="box-card">
          <Divider :title="'基本信息'" :tag="`${tag}`">
            <span slot="right">
              <span class="clickColor" style="font-weight:0" @click="getEdit('基本')">编辑</span>
            </span>
          </Divider>
          <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
            <el-descriptions-item label="工号">{{ detailInfo.jobNo || "--" }}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{ detailInfo.name || "--" }}</el-descriptions-item>

            <el-descriptions-item label="性别">{{ detailInfo.sex || "--" }}</el-descriptions-item>
            <el-descriptions-item label="现在住址">{{ detailInfo.address || "--" }}</el-descriptions-item>
            <el-descriptions-item label="民族">{{ detailInfo.nation || "--" }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">
              <div v-if="detailInfo.phone">
                <span v-if="!phoneShow" @click="phoneShow = true" style="color:cornflowerblue">
                  {{
                  detailInfo.phone.substr(0, 3) + new Array(detailInfo.phone.length -
                  7).join('*') + detailInfo.phone.substr(-4)
                  }}
                </span>
                <span v-else @click="phoneShow = false" style="color:cornflowerblue">
                  {{
                  detailInfo.phone || "--"
                  }}
                </span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="籍贯">{{ detailInfo.address || "--" }}</el-descriptions-item>
            <el-descriptions-item label="出生日期">{{ detailInfo.birthDay || "--" }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">
              <div v-if="detailInfo.idCardNumber">
                <span
                  v-if="!idCardNumberShow"
                  @click="idCardNumberShow = true"
                  style="color:cornflowerblue"
                >
                  {{
                  detailInfo.idCardNumber.substr(0, 3) + new Array(detailInfo.idCardNumber.length -
                  7).join('*') + detailInfo.idCardNumber.substr(-4)
                  }}
                </span>
                <span v-else @click="idCardNumberShow = false" style="color:cornflowerblue">
                  {{
                  detailInfo.idCardNumber ||
                  "--"
                  }}
                </span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="身份证头像面图片" prop="img" :show-overflow-tooltip="true">
              <el-popover placement="right-start" title="图片" trigger="hover">
                <img :src="detailInfo.idCardFront" alt srcset style="width: 300px" />
                <span
                  slot="reference"
                  trigger="hover"
                  class="el-icon-postcard font-color-brand"
                  style="cursor: pointer"
                  v-if="detailInfo.idCardFront"
                >查看头像面图片</span>
              </el-popover>
            </el-descriptions-item>
            <el-descriptions-item label="身份证国徽面图片" prop="img" :show-overflow-tooltip="true">
              <el-popover placement="right-start" title="图片" trigger="hover">
                <img :src="detailInfo.idCardBack" alt srcset style="width: 300px" />
                <span
                  slot="reference"
                  trigger="hover"
                  class="el-icon-postcard font-color-brand"
                  stylntracte="cursor: pointer"
                  v-if="detailInfo.idCardBack"
                >查看国徽面图片</span>
              </el-popover>
            </el-descriptions-item>
            <el-descriptions-item label="备注">{{ detailInfo.remark || "--" }}</el-descriptions-item>
          </el-descriptions>
          <Divider :title="'用工企业信息'"></Divider>
          <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
            <el-descriptions-item label="用工企业">
              <span v-if="detail.workEnterprise">{{ detail.workEnterprise.name }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="企业区域">
              <span v-if="detail.area">{{ detail.area.provinceName }}-{{ detail.area.cityName }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="企业门店">
              <span v-if="detail.store">{{ detail.store.name }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>

            <el-descriptions-item label="入职状态">
              <span v-if="detail.workState === '1'">待入职</span>
              <span v-if="detail.workState === '2'">在职</span>
              <span v-if="detail.workState === '3'">待离职</span>
              <span v-if="detail.workState === '4'">离职</span>
              <span v-if="detail.workState === '0'">被拒绝</span>
              <span v-if="!detail.workState">未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="工作性质">
              <span v-if="detail.kind === '1'">全职</span>
              <span v-if="detail.kind === '2'">灵活用工</span>
              <span v-if="!detail.workState">未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="入职合同">
              <span v-if="detail.enterContractStatus === '0'">未创建</span>
              <span v-if="detail.enterContractStatus === '2'">签署完成</span>
              <span v-if="detail.enterContractStatus === '100'">待发起</span>
              <span v-if="detail.enterContractStatus === '101'">签署中</span>
              <span v-if="detail.enterContractStatus === '103'">已完成</span>
              <span v-if="detail.enterContractStatus === '104'">审核不通过</span>
              <span v-if="detail.enterContractStatus === '105'">待企业签署</span>
              <span v-if="detail.enterContractStatus === '3'">失败</span>
              <span v-if="detail.enterContractStatus === '4'">拒签</span>
            </el-descriptions-item>
            <el-descriptions-item label="入职时间">
              <span v-if="detail.enterTime">{{ detail.enterTime }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="入职审核时间">
              <span v-if="detail.enterVerifyTime">{{ detail.enterVerifyTime }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="离职合同">
              <span v-if="detail.outContractStatus === '0'">未创建</span>
              <span v-if="detail.outContractStatus === '2'">签署完成</span>
              <span v-if="detail.outContractStatus === '100'">待发起</span>
              <span v-if="detail.outContractStatus === '101'">签署中</span>
              <span v-if="detail.outContractStatus === '103'">已完成</span>
              <span v-if="detail.outContractStatus === '104'">审核不通过</span>
              <span v-if="detail.outContractStatus === '105'">待企业签署</span>
              <span v-if="detail.outContractStatus === '3'">失败</span>
              <span v-if="detail.outContractStatus === '4'">拒签</span>
            </el-descriptions-item>
            <el-descriptions-item label="离职时间">
              <span v-if="detail.outTime">{{ detail.outTime }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
            <el-descriptions-item label="离职审核时间">
              <span v-if="detail.outVerifyTime">{{ detail.outVerifyTime }}</span>
              <span v-else>未分配</span>
            </el-descriptions-item>
          </el-descriptions>
          <Divider :title="'角色信息'">
            <span slot="right">
              <span
                class="clickColor"
                style="font-weight:0"
                v-access
                data-ctrl="modifyUserRoles"
                v-if="detailInfo.isAuthenticated"
                @click="setRole(detailInfo.code, detailInfo.roles)"
              >设置角色</span>
            </span>
          </Divider>
          <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
            <el-descriptions-item label="当前角色">
              <el-tag v-if="detail.role">{{ detail.role.name }}</el-tag>
              <el-tag v-else>游客</el-tag>
            </el-descriptions-item>
          </el-descriptions>

          <Divider :title="'紧急联系人'">
            <span slot="right">
              <span class="clickColor" style="font-weight:0" @click="getEdit('紧急')">编辑</span>
            </span>
          </Divider>
          <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
            <el-descriptions-item label="紧急联系人姓名">{{ detailInfo.sosUserName || "--" }}</el-descriptions-item>
            <el-descriptions-item label="紧急联系人电话">
              <div v-if="detailInfo.sosUserPhone">
                <span
                  v-if="!smsPhoneShow"
                  @click="smsPhoneShow = true"
                  style="color:cornflowerblue"
                >
                  {{
                  detailInfo.sosUserPhone.substr(0, 3) + new Array(detailInfo.sosUserPhone.length -
                  7).join('*') + detailInfo.sosUserPhone.substr(-4)
                  }}
                </span>
                <span v-else @click="smsPhoneShow = false" style="color:cornflowerblue">
                  {{
                  detailInfo.sosUserPhone || "--"
                  }}
                </span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="紧急联系人关系">{{ detailInfo.sosRelation || "--" }}</el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-tab-pane>
      <AddRole
        :dialogFormVisible.sync="dialogFormVisible"
        @close="close()"
        :setCode.sync="setCode"
        :setRoles.sync="setRoles"
      ></AddRole>
      <el-tab-pane label="银行卡信息" name="second">
        <el-card class="box-card">
          <BankInfo
            :list="detail.bankCard"
            ref="mychild"
            :bankFormVisible.sync="bankFormVisible"
            @IsbankFormVisible="bankFormVisibleShow"
          ></BankInfo>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="合同信息" name="third">
        <el-card class="box-card">
          <ContractInfo :list="detail.contract"></ContractInfo>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <div
      :style="
      detailInfo.roles == 'maker'
        ? 'margin-top: 16px; height: calc(100% - 57px);background:#fff;padding:20px;'
        : ''
    "
    >
      <!-- <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>学历：</span>
          </div>
          <div class="right_icon">
            <el-button type="text" size="small" @click="getEdit('学历')">编辑</el-button>
          </div>
        </div>
        <el-descriptions class="margin-top" style="margin-top:20px" :column="3">
          <el-descriptions-item label="学历">{{ detailInfo.education || "--" }}</el-descriptions-item>
          <el-descriptions-item label="毕业院校">{{ detailInfo.school || "--" }}</el-descriptions-item>
          <el-descriptions-item label="专业">{{ detailInfo.major || "--" }}</el-descriptions-item>
        </el-descriptions>
      </el-card>-->
      <!-- <EditDducationInfo :code="code" @close="close()" :educationShow.sync="educationShow"></EditDducationInfo> -->
      <!-- <el-card class="box-card">
      
      </el-card>-->
      <EditPerpeoInfo
        :code="detailInfo.code"
        @close="close()"
        :editPerpeoShow.sync="editPerpeoShow"
      ></EditPerpeoInfo>
    </div>
    <EditUserInfo
      :code="detailInfo.code"
      :staffCode="code"
      @close="close()"
      :dialogVisible.sync="dialogVisible"
    ></EditUserInfo>
    <AddBankCard
      :formVisible.sync="bankFormVisible"
      :userCode="code"
      @close="getBankCardList"
      v-if="isShow"
    ></AddBankCard>
  </div>
</template>
<script>
import { api } from '/src/api/base';

const staffInfo = api()('staff.info.json');
export default {
  components: {
    AddBankCard: () => import('./addBankCard.vue'),
    BankInfo: () => import('./bankInfo.vue'),
    EditUserInfo: () => import('./EditUserInfo.vue'),
    AddRole: () => import('../addRole.vue'),
    Divider: () => import('../../components/divider.vue'),
    // EditDducationInfo: () => import("./editDducationInfo.vue"),
    // EditDepartmentInfo: () => import("./editDepartmentInfo.vue"),
    EditPerpeoInfo: () => import('./editPerpeoInfo.vue'),
    ContractInfo: () => import('./contractInfo.vue'),
  },
  data () {
    return {
      code: '',
      isAuthenticated: false,
      phoneShow: false,
      idCardNumberShow: false,
      smsPhoneShow: false,
      detailInfo: {

      },
      detail: {},
      tag: '',
      activeName: 'first',
      setRoles: '',
      setCode: '',
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImgUrl: '',
      bankFormVisible: false,
      isShow: false,
      educationShow: false,
      editDepartmentShow: false,
      editPerpeoShow: false,
      roles: '',
      out: [],
      enter: []
    };
  },


  async created () {
    this.isShow = this.$route.query.isShow ? false : true
    this.code = this.$route.query.code || window.localStorage.getItem('code');
    this.roles = window.localStorage.getItem('roles')
    await this.getUserInfo();
  },
  methods: {
    setRole (code, roles) {
      this.setCode = code
      this.setRoles = roles

      this.dialogFormVisible = true
    },
    bankFormVisibleShow (data) {
      this.bankFormVisible = data
    },
    getBankCardList () {
      this.$refs.mychild.getBankCardList();
    },
    getEdit (name) {
      if (name === '学历') {
        this.educationShow = true;
      }
      else if (name === '部门') {
        this.editDepartmentShow = true;
      }
      else if (name === '基本') {
        this.dialogVisible = true;
      }
      else {
        this.editPerpeoShow = true;
      }
      // 
    },
    async getUserInfo () {
      const res = await staffInfo({
        staffCode: this.code,
        $ignoreRepeat: true
      });
      this.detailInfo = res.user;
      this.detail = res
      this.enter = res.contract.filter(it => it.type === '1')
      this.out = res.contract.filter(it => it.type === '2')
      // console.log(this.detail);
      // this.detailInfo = result;
      if (this.detailInfo.isAuthenticated) {
        this.tag = '已完成实名认证'
      }
      else {
        this.tag = '未完成实名认证'
      }
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    // goBack () {
    //   this.$router.go(-1);
    // },
    async close () {
      this.dialogVisible = false;
      this.editPerpeoShow = false;
      this.editDepartmentShow = false;
      this.dialogFormVisible = false
      this.educationShow = false;
      await this.getUserInfo();
    },
    goBack () {
      window.history.go(-1);
    },
  }
};
</script>
<style>
.el-descriptions-item__content {
  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;

  width: 60%;
}
</style>
<style lang="scss" scope>
.left-demo {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 1px dashed #000;
  font-size: 15px;

  .left-demo_left {
    display: flex;
    align-items: center;

    img {
      width: 39px;
      height: 35px;
    }

    span {
      margin-left: 15px;
    }
  }

  .left-demo_right {
    color: #b4b4b4;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
}

.showImgDialog .imgBox {
  display: flex;
}

.img-resource {
  width: 400px;
  height: 300px;
  margin: 0 auto;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}

.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marginBottom {
  margin-bottom: 12px;
}

.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}

.aType {
  color: #659fe5;
  cursor: pointer;
}

.tooltipClas {
  width: 250px;
}

.basic-info {
  .warning {
    color: #e6a23c;
  }

  .success {
    color: #67c23a;
  }

  .danger {
    color: #f56c6c;
  }

  .info {
    color: #409eff;
  }
}

.defaultTips {
  display: inline-block;
  margin: 0 8px;

  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}

.font-img {
  font-size: 26px;
  cursor: pointer;
}
</style>
